<template>
    <div v-if="color == 'red'">
        <svg :width="width" :height="height" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="68.4" height="68.4" rx="8" fill="url(#paint0_linear_1129_1239)" />
            <path
                d="M21.491 25.7527C22.8546 27.0967 24.2183 28.4406 25.524 29.9191C27.4813 32.1037 30.736 28.3455 28.5397 26.5006C26.4733 24.7836 24.5878 22.8615 22.5817 21.0761C21.6076 20.1162 20.1765 20.4312 19.4643 21.5826C17.9865 24.1522 16.2443 26.3335 14.1843 28.3934C13.1521 29.3572 14.3533 31.4357 15.6499 30.8603C17.995 29.7844 19.9343 27.8613 21.491 25.7527Z"
                fill="black" />
            <path
                d="M47.5065 20.2058C46.5324 19.2458 45.1013 19.5609 44.3891 20.7122C42.9113 23.2819 41.1691 25.4632 39.1091 27.5231C38.0769 28.4868 39.2781 30.5653 40.5747 29.9899C42.9198 28.9141 44.7965 26.9931 46.4158 24.8824C47.7794 26.2263 49.1431 27.5702 50.4488 29.0487C52.4061 31.2334 55.6608 27.4752 53.4645 25.6302C51.3354 23.9154 49.45 21.9933 47.5065 20.2058Z"
                fill="black" />
            <path
                d="M48.3206 41.7129C46.0404 42.8528 44.0873 44.3788 41.6077 45.1943C39.1281 46.0098 36.572 46.4304 34.002 46.4539C28.992 46.6288 24.5342 44.664 19.8979 42.9705C19.3899 42.7895 18.9143 43.535 19.2413 43.9211C20.0924 44.9517 20.8183 45.9865 21.9107 46.7436C23.1306 47.5624 24.4665 48.1121 25.8025 48.6619C28.472 49.6951 31.311 50.1924 34.1361 50.2925C37.0238 50.3905 40.0159 49.8884 42.7437 48.998C45.4089 48.1097 48.6888 46.8687 50.6165 44.6146C52.0641 42.9737 50.109 40.8553 48.3206 41.7129Z"
                fill="black" />
            <defs>
                <linearGradient id="paint0_linear_1129_1239" x1="34.2" y1="0" x2="34.2" y2="68.4"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E60000" />
                    <stop offset="1" stop-color="#660000" />
                </linearGradient>
            </defs>
        </svg>
    </div>
    <div v-else-if="color == 'green'">
        <svg :width="width" :height="height" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="68.4" height="68.4" rx="8" fill="url(#paint0_linear_1129_1257)" />
            <path
                d="M21.491 25.7527C22.8546 27.0967 24.2183 28.4406 25.524 29.9191C27.4813 32.1037 30.736 28.3455 28.5397 26.5006C26.4733 24.7836 24.5878 22.8615 22.5817 21.0761C21.6076 20.1162 20.1765 20.4312 19.4643 21.5826C17.9865 24.1522 16.2443 26.3335 14.1843 28.3934C13.1521 29.3572 14.3533 31.4357 15.6499 30.8603C17.995 29.7844 19.9343 27.8613 21.491 25.7527Z"
                fill="black" fill-opacity="0.95" />
            <path
                d="M47.5065 20.2058C46.5324 19.2458 45.1013 19.5609 44.3891 20.7122C42.9113 23.2819 41.1691 25.4632 39.1091 27.5231C38.0769 28.4868 39.2781 30.5653 40.5747 29.9899C42.9198 28.9141 44.7965 26.9931 46.4158 24.8824C47.7794 26.2263 49.1431 27.5702 50.4488 29.0487C52.4061 31.2334 55.6608 27.4752 53.4645 25.6302C51.3354 23.9154 49.45 21.9933 47.5065 20.2058Z"
                fill="black" fill-opacity="0.95" />
            <path
                d="M48.3206 41.7129C46.0404 42.8528 44.0873 44.3788 41.6077 45.1943C39.1281 46.0098 36.572 46.4304 34.002 46.4539C28.992 46.6288 24.5342 44.664 19.8979 42.9705C19.3899 42.7895 18.9143 43.535 19.2413 43.9211C20.0924 44.9517 20.8183 45.9865 21.9107 46.7436C23.1306 47.5624 24.4665 48.1121 25.8025 48.6619C28.472 49.6951 31.311 50.1924 34.1361 50.2925C37.0238 50.3905 40.0159 49.8884 42.7437 48.998C45.4089 48.1097 48.6888 46.8687 50.6165 44.6146C52.0641 42.9737 50.109 40.8553 48.3206 41.7129Z"
                fill="black" fill-opacity="0.95" />
            <defs>
                <linearGradient id="paint0_linear_1129_1257" x1="34.2" y1="68.4" x2="34.2" y2="0"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1F6600" />
                    <stop offset="1" stop-color="#45E600" />
                </linearGradient>
            </defs>
        </svg>
    </div>
    <div v-else-if="color == 'blue'">
        <svg :width="width" :height="height" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="68.4" height="68.4" rx="8" fill="url(#paint0_linear_1129_1267)" />
            <path
                d="M21.491 25.7527C22.8546 27.0967 24.2183 28.4406 25.524 29.9191C27.4813 32.1037 30.736 28.3455 28.5397 26.5006C26.4733 24.7836 24.5878 22.8615 22.5817 21.0761C21.6076 20.1162 20.1765 20.4312 19.4643 21.5826C17.9865 24.1522 16.2443 26.3335 14.1843 28.3934C13.1521 29.3572 14.3533 31.4357 15.6499 30.8603C17.995 29.7844 19.9343 27.8613 21.491 25.7527Z"
                fill="black" fill-opacity="0.95" />
            <path
                d="M47.5065 20.2058C46.5324 19.2458 45.1013 19.5609 44.3891 20.7122C42.9113 23.2819 41.1691 25.4632 39.1091 27.5231C38.0769 28.4868 39.2781 30.5653 40.5747 29.9899C42.9198 28.9141 44.7965 26.9931 46.4158 24.8824C47.7794 26.2263 49.1431 27.5702 50.4488 29.0487C52.4061 31.2334 55.6608 27.4752 53.4645 25.6302C51.3354 23.9154 49.45 21.9933 47.5065 20.2058Z"
                fill="black" fill-opacity="0.95" />
            <path
                d="M48.3206 41.7129C46.0404 42.8528 44.0873 44.3788 41.6077 45.1943C39.1281 46.0098 36.572 46.4304 34.002 46.4539C28.992 46.6288 24.5342 44.664 19.8979 42.9705C19.3899 42.7895 18.9143 43.535 19.2413 43.9211C20.0924 44.9517 20.8183 45.9865 21.9107 46.7436C23.1306 47.5624 24.4665 48.1121 25.8025 48.6619C28.472 49.6951 31.311 50.1924 34.1361 50.2925C37.0238 50.3905 40.0159 49.8884 42.7437 48.998C45.4089 48.1097 48.6888 46.8687 50.6165 44.6146C52.0641 42.9737 50.109 40.8553 48.3206 41.7129Z"
                fill="black" fill-opacity="0.95" />
            <defs>
                <linearGradient id="paint0_linear_1129_1267" x1="34.2" y1="0" x2="34.2" y2="68.4"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0045E6" />
                    <stop offset="1" stop-color="#001F66" />
                </linearGradient>
            </defs>
        </svg>

    </div>
    <div v-else-if="color == 'skyblue'">
        <svg :width="width" :height="height" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="68.4" height="68.4" rx="8" fill="url(#paint0_linear_1129_1248)" />
            <path
                d="M21.491 25.7527C22.8546 27.0967 24.2183 28.4406 25.524 29.9191C27.4813 32.1037 30.736 28.3455 28.5397 26.5006C26.4733 24.7836 24.5878 22.8615 22.5817 21.0761C21.6076 20.1162 20.1765 20.4312 19.4643 21.5826C17.9865 24.1522 16.2443 26.3335 14.1843 28.3934C13.1521 29.3572 14.3533 31.4357 15.6499 30.8603C17.995 29.7844 19.9343 27.8613 21.491 25.7527Z"
                fill="black" fill-opacity="0.95" />
            <path
                d="M47.5065 20.2058C46.5324 19.2458 45.1013 19.5609 44.3891 20.7122C42.9113 23.2819 41.1691 25.4632 39.1091 27.5231C38.0769 28.4868 39.2781 30.5653 40.5747 29.9899C42.9198 28.9141 44.7965 26.9931 46.4158 24.8824C47.7794 26.2263 49.1431 27.5702 50.4488 29.0487C52.4061 31.2334 55.6608 27.4752 53.4645 25.6302C51.3354 23.9154 49.45 21.9933 47.5065 20.2058Z"
                fill="black" fill-opacity="0.95" />
            <path
                d="M48.3206 41.7129C46.0404 42.8528 44.0873 44.3788 41.6077 45.1943C39.1281 46.0098 36.572 46.4304 34.002 46.4539C28.992 46.6288 24.5342 44.664 19.8979 42.9705C19.3899 42.7895 18.9143 43.535 19.2413 43.9211C20.0924 44.9517 20.8183 45.9865 21.9107 46.7436C23.1306 47.5624 24.4665 48.1121 25.8025 48.6619C28.472 49.6951 31.311 50.1924 34.1361 50.2925C37.0238 50.3905 40.0159 49.8884 42.7437 48.998C45.4089 48.1097 48.6888 46.8687 50.6165 44.6146C52.0641 42.9737 50.109 40.8553 48.3206 41.7129Z"
                fill="black" fill-opacity="0.95" />
            <defs>
                <linearGradient id="paint0_linear_1129_1248" x1="34.2" y1="68.4" x2="34.2" y2="0"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00665B" />
                    <stop offset="1" stop-color="#00E6CE" />
                </linearGradient>
            </defs>
        </svg>
    </div>
    <div v-else-if="color == 'yellow'">
        <svg :width="width" :height="height" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="68.4" height="68.4" rx="8" fill="url(#paint0_linear_1129_1275)" />
            <path
                d="M21.491 25.7527C22.8546 27.0967 24.2183 28.4406 25.524 29.9191C27.4813 32.1037 30.736 28.3455 28.5397 26.5006C26.4733 24.7836 24.5878 22.8615 22.5817 21.0761C21.6076 20.1162 20.1765 20.4312 19.4643 21.5826C17.9865 24.1522 16.2443 26.3335 14.1843 28.3934C13.1521 29.3572 14.3533 31.4357 15.6499 30.8603C17.995 29.7844 19.9343 27.8613 21.491 25.7527Z"
                fill="black" fill-opacity="0.95" />
            <path
                d="M47.5065 20.2058C46.5324 19.2458 45.1013 19.5609 44.3891 20.7122C42.9113 23.2819 41.1691 25.4632 39.1091 27.5231C38.0769 28.4868 39.2781 30.5653 40.5747 29.9899C42.9198 28.9141 44.7965 26.9931 46.4158 24.8824C47.7794 26.2263 49.1431 27.5702 50.4488 29.0487C52.4061 31.2334 55.6608 27.4752 53.4645 25.6302C51.3354 23.9154 49.45 21.9933 47.5065 20.2058Z"
                fill="black" fill-opacity="0.95" />
            <path
                d="M48.3206 41.7129C46.0404 42.8528 44.0873 44.3788 41.6077 45.1943C39.1281 46.0098 36.572 46.4304 34.002 46.4539C28.992 46.6288 24.5342 44.664 19.8979 42.9705C19.3899 42.7895 18.9143 43.535 19.2413 43.9211C20.0924 44.9517 20.8183 45.9865 21.9107 46.7436C23.1306 47.5624 24.4665 48.1121 25.8025 48.6619C28.472 49.6951 31.311 50.1924 34.1361 50.2925C37.0238 50.3905 40.0159 49.8884 42.7437 48.998C45.4089 48.1097 48.6888 46.8687 50.6165 44.6146C52.0641 42.9737 50.109 40.8553 48.3206 41.7129Z"
                fill="black" fill-opacity="0.95" />
            <defs>
                <linearGradient id="paint0_linear_1129_1275" x1="34.2" y1="0" x2="34.2" y2="68.4"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E67E00" />
                    <stop offset="1" stop-color="#663800" />
                </linearGradient>
            </defs>
        </svg>

    </div>
    <div v-else-if="color == 'pink'">
        <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 69 69" fill="none">
        <rect width="68.4" height="68.4" rx="8" fill="url(#paint0_linear_1129_1297)"/>
        <path d="M21.8017 25.3122C23.1177 26.7029 24.4336 28.0936 25.6869 29.6168C27.5668 31.8684 30.9506 28.2261 28.8201 26.3056C26.8148 24.5175 24.9976 22.5308 23.055 20.6765C22.115 19.6832 20.6738 19.948 19.9218 21.0739C18.3552 23.5904 16.538 25.7095 14.4074 27.6963C13.3421 28.6234 14.4701 30.7426 15.786 30.2128C18.1672 29.2194 20.1725 27.3652 21.8017 25.3122Z" fill="black"/>
        <path d="M47.9954 20.6765C47.0555 19.6832 45.6142 19.948 44.8622 21.0739C43.2956 23.5904 41.4784 25.7095 39.3478 27.6963C38.2825 28.6234 39.4105 30.7426 40.7264 30.2128C43.1077 29.2194 45.0502 27.3652 46.7422 25.3122C48.0581 26.7029 49.374 28.0936 50.6273 29.6168C52.5072 31.8684 55.8911 28.2261 53.7605 26.3056C51.6926 24.5175 49.8753 22.5308 47.9954 20.6765Z" fill="black"/>
        <path d="M48.0581 42.1992C45.7396 43.2587 43.7343 44.7157 41.2278 45.4441C38.7212 46.1726 36.152 46.5037 33.5828 46.4375C28.5697 46.4375 24.1832 44.3183 19.6088 42.464C19.1075 42.2654 18.6062 42.9938 18.9195 43.3912C19.7341 44.4508 20.4234 45.5104 21.4887 46.305C22.6793 47.166 23.9952 47.762 25.3112 48.358C27.9431 49.4838 30.7629 50.0798 33.5828 50.2785C36.4653 50.4772 39.4732 50.0798 42.2304 49.2851C44.9249 48.4904 48.2461 47.3646 50.2514 45.1792C51.7553 43.5899 49.8754 41.4045 48.0581 42.1992Z" fill="black"/>
        <defs>
            <linearGradient id="paint0_linear_1129_1297" x1="34.2" y1="0" x2="34.2" y2="68.4" gradientUnits="userSpaceOnUse">
                <stop stop-color="#D11451"/>
                <stop offset="1" stop-color="#5D0924"/>
            </linearGradient>
        </defs>
        </svg>
    </div>
    <div v-else-if="color == 'lightred'">  
        <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 69 69" fill="none">
            <rect width="68.4" height="68.4" rx="8" fill="url(#paint0_linear_1129_1307)"/>
            <path d="M21.8017 25.3122C23.1177 26.7029 24.4336 28.0936 25.6869 29.6168C27.5668 31.8684 30.9506 28.2261 28.8201 26.3056C26.8148 24.5175 24.9976 22.5308 23.055 20.6765C22.115 19.6832 20.6738 19.948 19.9218 21.0739C18.3552 23.5904 16.538 25.7095 14.4074 27.6963C13.3421 28.6234 14.4701 30.7426 15.786 30.2128C18.1672 29.2194 20.1725 27.3652 21.8017 25.3122Z" fill="black"/>
            <path d="M47.9954 20.6765C47.0555 19.6832 45.6142 19.948 44.8622 21.0739C43.2956 23.5904 41.4784 25.7095 39.3478 27.6963C38.2825 28.6234 39.4105 30.7426 40.7264 30.2128C43.1077 29.2194 45.0502 27.3652 46.7422 25.3122C48.0581 26.7029 49.374 28.0936 50.6273 29.6168C52.5072 31.8684 55.8911 28.2261 53.7605 26.3056C51.6926 24.5175 49.8753 22.5308 47.9954 20.6765Z" fill="black"/>
            <path d="M48.0581 42.1992C45.7396 43.2587 43.7343 44.7157 41.2278 45.4441C38.7212 46.1726 36.152 46.5037 33.5828 46.4375C28.5697 46.4375 24.1832 44.3183 19.6088 42.464C19.1075 42.2654 18.6062 42.9938 18.9195 43.3912C19.7341 44.4508 20.4234 45.5104 21.4887 46.305C22.6793 47.166 23.9952 47.762 25.3112 48.358C27.9431 49.4838 30.7629 50.0798 33.5828 50.2785C36.4653 50.4772 39.4732 50.0798 42.2304 49.2851C44.9249 48.4904 48.2461 47.3646 50.2514 45.1792C51.7553 43.5899 49.8754 41.4045 48.0581 42.1992Z" fill="black"/>
            <defs>
                <linearGradient id="paint0_linear_1129_1307" x1="34.2" y1="0" x2="34.2" y2="68.4" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E62E00"/>
                    <stop offset="1" stop-color="#661400"/>
                </linearGradient>
            </defs>
        </svg>
    </div>
    <div v-else-if="color == 'purple'">
        <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 69 69" fill="none">
        <rect width="68.4" height="68.4" rx="8" fill="url(#paint0_linear_1129_1288)"/>
        <path d="M21.8017 25.3122C23.1177 26.7029 24.4336 28.0936 25.6869 29.6168C27.5668 31.8684 30.9506 28.2261 28.8201 26.3056C26.8148 24.5175 24.9976 22.5308 23.055 20.6765C22.115 19.6832 20.6738 19.948 19.9218 21.0739C18.3552 23.5904 16.538 25.7095 14.4074 27.6963C13.3421 28.6234 14.4701 30.7426 15.786 30.2128C18.1672 29.2194 20.1725 27.3652 21.8017 25.3122Z" fill="black"/>
        <path d="M47.9954 20.6765C47.0555 19.6832 45.6142 19.948 44.8622 21.0739C43.2956 23.5904 41.4784 25.7095 39.3478 27.6963C38.2825 28.6234 39.4105 30.7426 40.7264 30.2128C43.1077 29.2194 45.0502 27.3652 46.7422 25.3122C48.0581 26.7029 49.374 28.0936 50.6273 29.6168C52.5072 31.8684 55.8911 28.2261 53.7605 26.3056C51.6926 24.5175 49.8753 22.5308 47.9954 20.6765Z" fill="black"/>
        <path d="M48.0581 42.1992C45.7396 43.2587 43.7343 44.7157 41.2278 45.4441C38.7212 46.1726 36.152 46.5037 33.5828 46.4375C28.5697 46.4375 24.1832 44.3183 19.6088 42.464C19.1075 42.2654 18.6062 42.9938 18.9195 43.3912C19.7341 44.4508 20.4234 45.5104 21.4887 46.305C22.6793 47.166 23.9952 47.762 25.3112 48.358C27.9431 49.4838 30.7629 50.0798 33.5828 50.2785C36.4653 50.4772 39.4732 50.0798 42.2304 49.2851C44.9249 48.4904 48.2461 47.3646 50.2514 45.1792C51.7553 43.5899 49.8754 41.4045 48.0581 42.1992Z" fill="black"/>
        <defs>
            <linearGradient id="paint0_linear_1129_1288" x1="34.2" y1="0" x2="34.2" y2="68.4" gradientUnits="userSpaceOnUse">
                <stop stop-color="#5D37AE"/>
                <stop offset="1" stop-color="#29194D"/>
            </linearGradient>
        </defs>
        </svg>
    </div>
</template>

<script>
export default {
    name: "ProfilePlaceHolder",
    props: ['height', 'width', 'color'],
    mounted() {
        
    }
}

</script>
<style>
#profile-icon-show {
    display: flex;
    align-items: center;
}
</style>
