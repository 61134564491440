<template>
    <div>
        <svg width="11px" height="11px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: sketchtool 63.1 (101010) - https://sketch.com -->
    <title>6968AA94-3752-4831-8D41-93B8DC2982E2</title>
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Spec" transform="translate(-672.000000, -554.000000)" fill="red" class="filllogout" fill-rule="nonzero">
            <g id="Logout" transform="translate(672.500000, 554.457178)">
                <g id="logout-(1)" transform="translate(0.000000, 0.461538)">
                    <path d="M10.5025538,0 L1.31283077,0 C0.587476923,0 0,0.587476923 0,1.31283077 L0,3.93846154 L1.31283077,3.93846154 L1.31283077,1.31283077 L10.5025846,1.31283077 L10.5025846,10.5025846 L1.31283077,10.5025846 L1.31283077,7.87692308 L0,7.87692308 L0,10.5025538 C0,11.2279077 0.587476923,11.8153846 1.31283077,11.8153846 L10.5025846,11.8153846 C11.2279077,11.8153846 11.8153846,11.2279077 11.8153846,10.5025538 L11.8153846,1.31283077 C11.8153846,0.587476923 11.2279077,0 10.5025538,0 Z" id="Path"></path>
                    <polygon id="Path" points="4.65067692 8.26092308 5.57947692 9.18975385 8.86153846 5.90769231 5.57947692 2.62563077 4.65067692 3.55446154 6.34747692 5.25129231 0 5.25129231 0 6.56409231 6.34747692 6.56409231"></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>
</div>

</template>

<script>

export default{
    name:"logout icon"
}

</script>


<style lang="scss">
  @import "@/sass/_variables.scss";
  @import "@/sass/_components.scss";

.filllogout{
    fill:$clr-saina-light-dark-main-bg
 }

</style>