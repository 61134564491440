<template>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
    <svg width="36px" height="36px" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"/>        
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>        
        <g id="SVGRepo_iconCarrier"> 
            <g clip-path="url(#000clip0_429_10970)"> 
                <circle cx="12" cy="11.999" r="9" stroke="#000" stroke-width="1.56" stroke-linecap="round" stroke-linejoin="round"/> 
                <path d="M12 9V15" stroke="#000" stroke-width="1.56" stroke-linecap="round" stroke-linejoin="round"/> 
                <path d="M9 12H15" stroke="#000" stroke-width="1.56" stroke-linecap="round" stroke-linejoin="round"/> 
            </g> 
            <defs> 
                <clipPath id="clip0_429_10970"> 
                    <rect width="24" height="24" fill="white"/> 
                </clipPath> 
            </defs> 
        </g>        
    </svg>
</template>
<script>
export default {
    name: 'AddProfile'
}
</script>
<style scoped lang="scss">
</style>